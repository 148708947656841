import * as DeleteAddressActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { addressService } from "../address.service";

export function* deleteAddressSaga({
  payload,
}: DeleteAddressActions.DeleteAddressAction) {
  try {
    yield call(addressService.deleteAddress, payload);
    yield put(DeleteAddressActions.deleteAddressSuccess());
  } catch (error) {
    yield put(DeleteAddressActions.deleteAddressFailure(error));
  }
}
export const DeleteAddressSagas = [
  takeLatest(DeleteAddressActions.DELETE_ADDRESS_TYPE, deleteAddressSaga)
];
