import { Address } from "../address.interface";

export interface CreateAddressState {
  isLoading: boolean;
  error: any;
  address: Address[];
}

export const createAddressInitialState: CreateAddressState = {
  isLoading: false,
  error: null,
  address: null
};
