import React, { useEffect, useState } from "react";
import { translate } from "react-i18nify";

import { useDispatch, useSelector } from "react-redux";
import { GlobalAppState } from "@goodfood/store";
import { useRouter } from "next/router";
import { CreatePaymentActions } from "@goodfood/payment";
import Router from "next/router";
import { GetMealPlanSubscriptionActions } from "@mealhaus/meal-plan-subscription";


export enum PaymentMethod {
  POD = "PAY ON DELIVERY",
  DC = "DEBIT CARD",
}

export const PaymentForm = ({ user }) => {
  const isCreatingPayment = useSelector(
    (state: GlobalAppState) => state.createPayment.isLoading
  );
  let flw;
  useEffect(() => {
    return () => {
      flw && flw.close()
    }
  })
  const mealPlanSubscription = useSelector((state: GlobalAppState) => state.getMealPlanSubscription.mealPlanSubscription);

  const makePayment = () => {
    // @ts-ignore
    if (!window || !window.FlutterwaveCheckout) {
      return
    }
    // @ts-ignore
    flw = window.FlutterwaveCheckout({
      public_key: process.env.NEXT_PUBLIC_FLUTTER_WAVE_PK,
      tx_ref: mealPlanSubscription.id,
      amount: mealPlanSubscription.totalAmount,
      currency: "NGN",
      payment_options: "card",
      // payment_plan: 6866,
      customer: {
        email: user.email,
        phone_number: mealPlanSubscription.phoneNumber,
        name: user.name || user.email,
      },
      callback: function (data) {
        if (data.status === "successful") {
          Router.push({
            pathname: "/success",
          });
        }
        else {

        }
      },
      customizations: {
        title: "Mealhaus",
        description: "Payment for meal kit",
        logo: "https://mealhaus.imgix.net/static/circle_logo.png",
      },
    });
  }

  const [selectedOption, onChangeSelectedOption] = useState(PaymentMethod.DC)
  const onChangeValue = (event) => {
    onChangeSelectedOption(event.target.value);
  }
  const handleSubmit = () => {
    if (selectedOption === PaymentMethod.DC) {
      return makePayment()
    }
    dispatch(
      CreatePaymentActions.createPayment({
        mealPlanSubscriptionId: mealPlanSubscription.id,
        amount: mealPlanSubscription.totalAmount,
        method: "PAY ON DELIVERY",
      })
    )
  }

  const dispatch = useDispatch();
  const { query } = useRouter();

  useEffect(() => {
    dispatch(GetMealPlanSubscriptionActions.getMealPlanSubscription((query as {[x:string]: string}).mealPlanSubscriptionId));
  }, []);

  if (!mealPlanSubscription) {
    return <div />;
  }

  return (
    <div className="container mt-md-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 p-2 px-4">
          <div className="text-large d-md-none">{translate("paymentPage.Payment")}</div>
          <hr className="d-md-none" />
          <div className="font-weight-light d-flex justify-content-between align-items-center mb-2 text-small">
            <div className="">{translate("paymentPage.recipePerWeek")}</div>
            <div className="text-small">{mealPlanSubscription.numberOfRecipes}</div>
          </div>
          <div className="font-weight-light d-flex justify-content-between align-items-center mb-2 text-small">
            <div className="">{translate("paymentPage.numberOfWeeks")}</div>
            <div className="text-small">{mealPlanSubscription.numberOfWeeks}</div>
          </div>
          <div className="font-weight-light d-flex justify-content-between align-items-center mb-2 text-small">
            <div className="">{translate("paymentPage.numberOfServings")} per recipe</div>
            <div className="text-small">{mealPlanSubscription.numberOfServings}</div>
          </div>
          <div className="font-weight-light d-flex justify-content-between align-items-center mb-2 text-small">
            <div className="">Total amount of servings</div>
            <div className="text-small">{mealPlanSubscription.numberOfServings * mealPlanSubscription.numberOfRecipes * mealPlanSubscription.numberOfWeeks}</div>
          </div>
          <div className="font-weight-light d-flex justify-content-between align-items-center mb-2 text-small">
            <div className="">Amount per serving</div>
            <div className="text-small">N500</div>
          </div>
          <hr />
          <div className="font-weight-light">
            <div className="mb-2">
              {translate("paymentPage.DeliveryAddress")}
            </div>
            <div className="text-small">
              {mealPlanSubscription.address.houseNumber} {mealPlanSubscription.address.street}
            </div>
            <div>{mealPlanSubscription.address.address}</div>
          </div>
          <hr />
          <div>
            <div className="text-large mb-2">
              {translate("paymentPage.PaymentMethod")}
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label className="mb-0 text-small">
                {translate("paymentPage.payWithDebitCard")}
              </label>
              <input
                type="radio"
                name="paymentMethod"
                value={PaymentMethod.DC}
                checked={selectedOption === PaymentMethod.DC}
                onChange={onChangeValue}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <label className="mb-0 text-small">
                {translate("paymentPage.payOnDelivery")}
              </label>
              <input
                type="radio"
                name="paymentMethod"
                value={PaymentMethod.POD}
                checked={selectedOption === PaymentMethod.POD}
                onChange={onChangeValue}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex justify-content-between align-items-center text-small font-weight-light mb-1">
              <span>{translate("paymentPage.mealPlanSubscription")}</span>
              <span>{`N ${mealPlanSubscription.totalAmount}`}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center text-small font-weight-light mb-1">
              <span>{translate("paymentPage.delivery")}</span>
              <span>-</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span>{translate("paymentPage.total")}</span>
              <span>{`N ${mealPlanSubscription.totalAmount}`}</span>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block mb-2 mt-4 btn-lg text-small"
              onClick={handleSubmit

              }
              disabled={isCreatingPayment}
            >
              {translate(
                isCreatingPayment
                  ? "mealHaus.loading"
                  : "paymentPage.confirmPayment"
              )}
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`

      `}</style>
    </div>
  );
};
