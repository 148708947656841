import * as Actions from "./actions";
import { DeleteAddressState, deleteAddressInitialState } from "./state";

export const deleteAddressReducer = (
  state: DeleteAddressState = deleteAddressInitialState,
  action: Actions.DeleteAddressActions
): DeleteAddressState => {
  switch (action.type) {
    case Actions.DELETE_ADDRESS_TYPE:
      return { ...state, isLoading: true };
    case Actions.DELETE_ADDRESS_SUCCESS_TYPE:
      return { ...state, isLoading: false };
    case Actions.DELETE_ADDRESS_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
