import * as GetAddressesActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { Address } from '../address.interface';
import { addressService } from '../address.service';
import { CreateAddressActions } from '../createAddress';
import { DeleteAddressActions } from '../deleteAddress';

export function* getAddressesSaga() {
    try {
        const addresses: Address[] = yield call(addressService.getAddresses);
        yield put(GetAddressesActions.getAddressesSuccess(addresses));
    } catch (error) {
        yield put(GetAddressesActions.getAddressesFailure(error));
    }
}

export const GetAddressesSagas = [takeLatest([GetAddressesActions.GET_ADDRESSES_TYPE, CreateAddressActions.CREATE_ADDRESS_SUCCESS_TYPE, DeleteAddressActions.DELETE_ADDRESS_SUCCESS_TYPE], getAddressesSaga)];
