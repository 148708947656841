import { Address } from "../address.interface";

export interface GetAddressesState {
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
    addresses: Address[]
}

export const getAddressesInitialState: GetAddressesState = {
    isLoading: false,
    isLoaded: false,
    error: null,
    addresses: []
};

