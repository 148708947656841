import React from "react";
import { Modal } from "./Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { AddressForm } from "./AddressForm";
import { useSelector, useDispatch } from "react-redux";
import { GlobalAppState } from "@goodfood/store";
import { Address, CreateAddressActions, CreateAddressModalActions } from "@mealhaus/address";
import { ValidationMessages } from "@mealhaus/enums";
import { translate } from "react-i18nify";

interface Props {

}

export const DEFAULT_COUNTRY = "NG";
export const DEFAULT_STATE = "LAGOS";
export const REGEXP = /^(0)([1-9]{1})([0-9]{9})$|^(234|\+234)([1-9]{1})([0-9]{9})$/;

export const AddressFormInitialValues = {
  address: {
    address: "",
    country: DEFAULT_COUNTRY,
    state: DEFAULT_STATE,
    houseNumber: "",
    street: "",
  },
};



const AddressFormValidationSchema = Yup.object().shape({
  address: Yup.object({
    address: Yup.string().required(ValidationMessages.Required),
    country: Yup.string().required(ValidationMessages.Required),
    state: Yup.string().required(ValidationMessages.Required),
    street: Yup.string().required(ValidationMessages.Required),
    houseNumber: Yup.string().required(ValidationMessages.Required),
  })
});

export const CreateAddressModal = ({ }: Props) => {
  const dispatch = useDispatch();

  const createAddressModalIsOpen = useSelector(
    (state: GlobalAppState) => state.createAddressModal.isOpen
  );

  const createAddressState = useSelector(
    (state: GlobalAppState) => state.createAddress
  );

  const close = () => {
    dispatch(CreateAddressModalActions.closeCreateAddressModal())
  }
  return (
    <Formik
      enableReinitialize
      initialValues={AddressFormInitialValues}
      validationSchema={AddressFormValidationSchema}
      onSubmit={(values) => {
        dispatch(CreateAddressActions.createAddress(values.address));
      }}
    >
      {(formik) => {
        return (
          <Modal
            isOpen={createAddressModalIsOpen}
            close={close}
            title={translate("createAddressModal.newAddress")}
            footer={
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={close}>{translate("createAddressModal.close")}</button>
                <button type="button" className="btn btn-primary" disabled={!formik.isValid || createAddressState.isLoading} onClick={formik.submitForm}>{translate("createAddressModal.save")}</button>
              </div>
            }
          >
            <AddressForm />
          </Modal>
        );
      }}
    </Formik>

  )
};
