import * as Actions from "./actions";
import { CreateAddressState, createAddressInitialState } from "./state";

export const createAddressReducer = (
  state: CreateAddressState = createAddressInitialState,
  action: Actions.CreateAddressActions
): CreateAddressState => {
  switch (action.type) {
    case Actions.CREATE_ADDRESS_TYPE:
      return { ...state, isLoading: true };
    case Actions.CREATE_ADDRESS_SUCCESS_TYPE:
      return { ...state, isLoading: false };
    case Actions.CREATE_ADDRESS_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
