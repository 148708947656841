import * as Actions from "./actions";
import { CreateAddressModalState, createAddressModalInitialState } from "./state";
import { CreateAddressActions } from "../createAddress";

export const createAddressModalReducer = (
  state: CreateAddressModalState = createAddressModalInitialState,
  action: Actions.CreateAddressModalActions | CreateAddressActions.CreateAddressActions
): CreateAddressModalState => {
  switch (action.type) {
    case Actions.OPEN_CREATE_ADDRESS_MODAL_TYPE:
      return { ...state, isOpen: true };
    case Actions.CLOSE_CREATE_ADDRESS_MODAL_TYPE:
    case CreateAddressActions.CREATE_ADDRESS_SUCCESS_TYPE:
    case CreateAddressActions.CREATE_ADDRESS_FAILURE_TYPE:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
