import React, { useEffect, useState } from 'react';
import { GetRecipesActions, SelectOtpRecipeCard } from '@mealhaus/recipes';
import { useSelector, useDispatch } from "react-redux";
import { GlobalAppState } from "@goodfood/store";
import { Order, OtpActions } from '@mealhaus/otp';
import { useRouter } from 'next/router';
import { routes } from '@mealhaus/navigation';


interface BoxItem {
    id: string;
    quantity: number;
    title: string
}

export const SelectOtpRecipes = () => {
    const recipes = useSelector(
        (state: GlobalAppState) => state.getRecipes.recipes
    );

    const { order } = useSelector(
        (state: GlobalAppState) => state.otp
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetRecipesActions.getRecipes());
    }, []);

    const boxInRedux= (order as Order).orderRecipes
    const [box, setBox] = useState<{ [x: string]: BoxItem }>({})

    useEffect(() => {
        if (boxInRedux) {
            setBox(boxInRedux.reduce((acc,orderRecipe)=>({...acc,[orderRecipe.id]:orderRecipe}),{}))
        }
    }, [boxInRedux])


    const orderRecipes = Object.values(box)

    const addRecipeToBox = (id: string, title: string) => {
        const boxItem = box[id]
        if (!!boxItem) {
            setBox({ ...box, [id]: { ...boxItem, quantity: boxItem.quantity + 1 } })
        }
        else {
            setBox({ ...box, [id]: { id, quantity: 1, title } })
        }
    }

    const removeRecipeFromBox = (id: string) => {
        const boxItem = box[id]

        if (!!boxItem && boxItem.quantity === 1) {
            const { [id]: currentItem, ...restOfIds } = box
            setBox(restOfIds)
            return
        }
        if (!!boxItem && boxItem.quantity > 1) {
            setBox({ ...box, [id]: { ...boxItem, quantity: boxItem.quantity - 1 } })
            return
        }
    }

    const isCreatingOrder = useSelector(
        (state: GlobalAppState) => state.otp.isLoading
    );

    const router = useRouter();
    const numberOfRecipes = orderRecipes.reduce((acc,orderRecipe)=>acc+orderRecipe.quantity,0)
    return (
        <>
            <div className="row mb-4">
                {
                    recipes.map((recipe) => {
                        return (
                            <div className="col-lg-4 mb-4 mb-lg-0" key={recipe.id}>
                                <SelectOtpRecipeCard boxItem={(() => box[recipe.id])()} recipe={recipe} addRecipeToBox={addRecipeToBox} removeRecipeFromBox={removeRecipeFromBox} />
                            </div>
                        );
                    })
                }
            </div>
            <div className="bg-white p-3 position-fixed d-flex justify-content-between align-items-center w-100 footer border-top">
                <div>Total: N{numberOfRecipes * 1000}</div>
                <button className="btn btn-primary text-small" onClick={() => {
                    dispatch(OtpActions.saveOrder({ orderRecipes }))
                    router.push(routes.OTP_ADDRESS)
                }} disabled={isCreatingOrder || numberOfRecipes === 0}>{isCreatingOrder ? "loading..." : "Delivery address >"}</button>
            </div>

            <style jsx>{`
            .footer{
                bottom: 0;
                left:0;
            }
            `}</style>
        </>
    );
};
