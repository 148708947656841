import { createAction } from "@goodfood/utils";
import { Address } from "../address.interface";

export type CreateAddressActions =
  | CreateAddressAction
  | CreateAddressSuccessAction
  | CreateAddressFailureAction;

export const CREATE_ADDRESS_TYPE = "[GOOD FOOD SHOP] Create address";
export type CreateAddressAction = ReturnType<typeof createAddress>;
export const createAddress = (address: Partial<Address>) =>
  createAction(CREATE_ADDRESS_TYPE, address);

export const CREATE_ADDRESS_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Create address success";
export type CreateAddressSuccessAction = ReturnType<typeof createAddressSuccess>;
export const createAddressSuccess = (address: Address) =>
  createAction(CREATE_ADDRESS_SUCCESS_TYPE, address);

export const CREATE_ADDRESS_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Create address failure";
export type CreateAddressFailureAction = ReturnType<typeof createAddressFailure>;
export const createAddressFailure = (error: any) =>
  createAction(CREATE_ADDRESS_FAILURE_TYPE, error);
