import * as React from 'react';
import classnames from 'classnames';
import { BsThreeDotsVertical } from 'react-icons/bs';

interface Props {
    className?: string;
    isOpen?: boolean;
    children: React.ReactElement[] | React.ReactElement;
    anchorHorizontal?: 'left' | 'right';
}

export const Menu = ({ className, children, isOpen = false, anchorHorizontal = 'right' }: Props) => {
    const [openMenu, setOpenMenu] = React.useState(isOpen);
    const ref = React.useRef<HTMLDivElement>(null);

    const onOpenMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenMenu(!openMenu);
    };

    const handleClick = (e: any) => {
        if (ref && ref.current!.contains(e.target)) {
            return;
        }
        setOpenMenu(false);
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <React.Fragment>
            <div onClick={onOpenMenu} className={classnames('position-relative', className)} ref={ref}>
                <span className="pointer">
                    <BsThreeDotsVertical color="#97a3b4"/>
                </span>
                {openMenu && (
                    <div className="p-2 menu position-absolute bg-white rounded">
                        <ul className="list-unstyled text-left mb-0">
                            {React.Children.map(children, (child, index) => {
                                return (
                                    <_MenuItem {...child.props} closeMenu={() => setOpenMenu(false)} key={index}>
                                        {child.props.children}
                                    </_MenuItem>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
            <style jsx>{`
                .menu {
                    min-width: 12.1rem;
                    max-width: 20rem;
                    width: max-content;
                    ${anchorHorizontal}: 0;
                    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 3px 4px 0 rgba(10, 31, 68, 0.1);
                    z-index: 400;
                }
            `}</style>
        </React.Fragment>
    );
};

interface MenuItemProps {
    className?: string;
    children: string;
    onClick?: () => void;
}

interface _MenuItemProps extends MenuItemProps {
    closeMenu: () => void;
}

export const MenuItem = (_: MenuItemProps) => null;

export const _MenuItem = ({ children, className, onClick, closeMenu }: _MenuItemProps) => {
    const onMenuitemClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick && onClick();
        closeMenu();
    };
    return (
        <React.Fragment>
            <li className={classnames('menu-item p-2 pointer text-small', className)} onClick={onMenuitemClick}>
                {children}
            </li>
            <style jsx>{`
                .menu-item:hover {
                    background: #f8f9fa;
                }
            `}</style>
        </React.Fragment>
    );
};
