import React, { useState, useEffect } from 'react';
import { AccordionItemProps, _AccordionItem } from './AccordionItem';

export const Accordion = ({ children, allowMultipleOpen }: { children: React.ReactNode; allowMultipleOpen?: boolean }) => {
    const [openItems, setOpenItems] = useState({} as { [x: number]: boolean });

    useEffect(() => {
        let openItems = {} as { [x: number]: boolean };
        React.Children.forEach(children, (child, index) => {
            if (!React.isValidElement<AccordionItemProps>(child)) {
                return child;
            }
            if (child.props.isOpen) {
                openItems[index] = true;
            }
        });
        setOpenItems(openItems);
    }, []);

    const onItemClick = (onClick?: (id: number) => void) => (id: number) => {
        const isOpen = !!openItems[id];
        const newOpenItems = allowMultipleOpen ? { ...openItems, [id]: !isOpen } : { [id]: !isOpen };
        setOpenItems(newOpenItems);
        onClick && onClick(id);
    };

    return (
        <div>
            {React.Children.map(children, (child, index) => {
                if (!React.isValidElement<AccordionItemProps>(child)) {
                    return child;
                }
                return (
                    <_AccordionItem
                        {...child.props}
                        isOpen={!!openItems[index]}
                        id={index}
                        onClick={onItemClick(child.props?.onClick)}
                        key={index}>
                        {child.props.children}
                    </_AccordionItem>
                );
            })}
            <style jsx>{``}</style>
        </div>
    );
};
