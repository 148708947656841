import { createAction } from "@goodfood/utils";

export type CreateAddressModalActions =
  | OpenCreateAddressModalAction
  | CloseCreateAddressModalAction

export const OPEN_CREATE_ADDRESS_MODAL_TYPE = "[GOOD FOOD SHOP] Open create address modal";
export type OpenCreateAddressModalAction = ReturnType<typeof openCreateAddressModal>;
export const openCreateAddressModal = () =>
  createAction(OPEN_CREATE_ADDRESS_MODAL_TYPE);

export const CLOSE_CREATE_ADDRESS_MODAL_TYPE =
  "[GOOD FOOD SHOP] Close create address modal";
export type CloseCreateAddressModalAction = ReturnType<typeof closeCreateAddressModal>;
export const closeCreateAddressModal = () =>
  createAction(CLOSE_CREATE_ADDRESS_MODAL_TYPE);
