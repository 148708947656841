import { createAction } from "@goodfood/utils";

export type DeleteAddressActions =
  | DeleteAddressAction
  | DeleteAddressSuccessAction
  | DeleteAddressFailureAction;

export const DELETE_ADDRESS_TYPE = "[GOOD FOOD SHOP] Delete address";
export type DeleteAddressAction = ReturnType<typeof deleteAddress>;
export const deleteAddress = (id: string) =>
  createAction(DELETE_ADDRESS_TYPE, id);

export const DELETE_ADDRESS_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Delete address success";
export type DeleteAddressSuccessAction = ReturnType<typeof deleteAddressSuccess>;
export const deleteAddressSuccess = () =>
  createAction(DELETE_ADDRESS_SUCCESS_TYPE);

export const DELETE_ADDRESS_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Delete address failure";
export type DeleteAddressFailureAction = ReturnType<typeof deleteAddressFailure>;
export const deleteAddressFailure = (error: any) =>
  createAction(DELETE_ADDRESS_FAILURE_TYPE, error);
