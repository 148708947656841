import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Colors } from '@goodfood/utils';
import { BsX } from 'react-icons/bs';

interface Props {
    isOpen: boolean
    children: React.ReactNode
    close: () => void
    title: string
    footer?: React.ReactNode
}

export const Modal = ({ isOpen, children, close, title, footer }: Props) => {
    useEffect(() => {
        const bodyElement = document.getElementsByTagName("body")
        if (isOpen) {
            bodyElement[0].classList.add("modal-open")
            const modalBackdrop = document.createElement(`div`)
            modalBackdrop.classList.add("modal-backdrop", "fade", "show")
            modalBackdrop.id = "modal-backdrop"
            bodyElement[0].appendChild(modalBackdrop)
        }
        else {
            bodyElement[0].classList.remove("modal-open")
            const modalBackdrop = document.getElementById("modal-backdrop")
            if (modalBackdrop) {
                bodyElement[0].removeChild(modalBackdrop)
            }
        }
    }, [isOpen])

    return (
        <div>
            <div className={classNames("modal fade", { show: isOpen, "d-block": isOpen })} tabIndex={-1} role="dialog" aria-labelledby="modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-large">{title}</h5>
                            <div className="close pointer" aria-label="Close" onClick={close}>
                                <span aria-hidden="true"><BsX /></span>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                children
                            }
                        </div>
                      {footer}
                    </div>
                </div>
            </div>
            <style jsx>{`
             
            `}</style>
        </div>
    );
};
