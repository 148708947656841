import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BsChevronCompactDown } from 'react-icons/bs';

export interface _AccordionItemProps extends AccordionItemProps {
    onClick: (id: number) => void;
    id: number;
}

export interface AccordionItemProps {
    isOpen?: boolean;
    children?: React.ReactNode;
    label: React.ReactNode;
    onClick?: (id: number) => void;
    disabled?: boolean;
    headerClasses?: string;
}
export const AccordionItem = (_: AccordionItemProps) => null;

export const _AccordionItem = ({ isOpen, children, label, onClick, id, disabled, headerClasses }: _AccordionItemProps) => {
    const [iconClasses, setIconClasses] = useState('vicon icon icon-angle-down d-flex');

    useEffect(() => {
        setIconClasses(!isOpen ? 'icon d-flex' : 'icon d-flex rotate');
    });

    function toggleAccordion() {
        setIconClasses(isOpen ? 'icon d-flex' : 'icon d-flex rotate');

        onClick(id);
    }

    return (
        <React.Fragment>
            <div className="bg-white border mb-3 root rounded">
                <div
                    className={classNames(headerClasses, `d-flex justify-content-between align-items-center header p-2 px-3 pointer`, {
                        'border-bottom': isOpen
                    })}
                    onClick={toggleAccordion}>
                    <div className="mr-4">
                        {/* <i className={classNames(iconClasses, { ['text-light']: disabled })} /> */}
                        <BsChevronCompactDown className={classNames(iconClasses, { ['text-light']: disabled })} />
                    </div>
                    <div className="flex-grow-1">{label}</div>
                </div>
                <AnimateHeight height={isOpen ? 'auto' : 0} duration={150}>
                    {children}
                </AnimateHeight>
            </div>
            <style jsx>{`
                .icon {
                    margin-left: auto;
                    transition: transform 150ms linear;
                }
            `}</style>
        </React.Fragment>
    );
};
