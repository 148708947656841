import * as Actions from './actions';
import { GetAddressesState, getAddressesInitialState } from './state';

export const getAddressesReducer = (
    state: GetAddressesState = getAddressesInitialState,
    action: Actions.GetAddressesActions
): GetAddressesState => {
    switch (action.type) {
        case Actions.GET_ADDRESSES_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_ADDRESSES_SUCCESS_TYPE:
            return { ...state, isLoading: false, addresses: action.payload, isLoaded: true };
        case Actions.GET_ADDRESSES_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload, isLoaded: true };
        default:
            return state;
    }
};
