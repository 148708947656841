import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { BsCheck, BsTrash } from 'react-icons/bs';
import { Colors } from '@goodfood/utils';
import { Menu, MenuItem } from './Menu';
import { translate } from 'react-i18nify';

interface Props<T> {
    items: T[],
    defaultId: string,
    onSelect: (item: T) => void,
    onDelete: (id: string) => void,
    labelProperty: string
}
interface SelectableItem {
    id: string
}

export const SelectableList = <T extends SelectableItem>({ items, defaultId, onSelect, onDelete, labelProperty }: Props<T>) => {
    const [selectedItemId, setSelectedItemId] = useState<string>(defaultId);

    const onItemClick = (item: T) => {
        setSelectedItemId(item.id);
        onSelect(item)
    };
    useEffect(() => {
        setSelectedItemId(defaultId)
    }, [defaultId])

    return (
        <div>
            {
                items.map((item, idx) => (
                    <div className="bg-white border mb-2 root rounded" key={item.id}>
                        <div
                            className={classNames(`d-flex justify-content-between align-items-center header p-2 px-3`)}>

                            <div className="flex-grow-1 text-small overflow-hidden text-nowrap text-overflow-ellipsis">{item[labelProperty]}</div>
                            
                            <div className="border border-primary rounded-circle check-circle d-flex align-items-center justify-content-center pointer ml-3" onClick={() => onItemClick(item)}>
                                {
                                    selectedItemId === item.id && <BsCheck color={Colors.primary} />
                                }
                            </div>
                            <Menu className="ml-3">
                                {/* <MenuItem
                                    className="text-light"
                                    >
                                    {translate('selectableList.edit')}
                                </MenuItem> */}
                                <MenuItem
                                    className="text-danger"
                                    onClick={() => onDelete(item.id)}>
                                    {translate('selectableList.delete')}
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                ))
            }
            <style jsx>{`
             .check-circle{
                width: 1.5rem;
                min-width: 1.5rem;
                height: 1.5rem;
              }
            `}</style>
        </div>
    );
};
