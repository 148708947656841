import { createAction } from "@goodfood/utils";
import { Address } from "../address.interface";

export type GetAddressesActions =
  | GetAddressesAction
  | GetAddressesSuccessAction
  | GetAddressesFailureAction;

export const GET_ADDRESSES_TYPE = "[GOOD FOOD SHOP] Get Addresses";
export type GetAddressesAction = ReturnType<typeof getAddresses>;
export const getAddresses = () =>
  createAction(GET_ADDRESSES_TYPE);

export const GET_ADDRESSES_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Get Addresses success";
export type GetAddressesSuccessAction = ReturnType<typeof getAddressesSuccess>;
export const getAddressesSuccess = (addresses: Address[]) =>
  createAction(GET_ADDRESSES_SUCCESS_TYPE, addresses);

export const GET_ADDRESSES_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Get Addresses failure";
export type GetAddressesFailureAction = ReturnType<typeof getAddressesFailure>;
export const getAddressesFailure = (error: any) =>
  createAction(GET_ADDRESSES_FAILURE_TYPE, error);
