import * as CreateAddressActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { addressService } from "../address.service";

export function* createAddressSaga({
  payload,
}: CreateAddressActions.CreateAddressAction) {
  try {
    const Address = yield call(addressService.createAddress, payload);
    yield put(CreateAddressActions.createAddressSuccess(Address));
  } catch (error) {
    yield put(CreateAddressActions.createAddressFailure(error));
  }
}
export const CreateAddressSagas = [
  takeLatest(CreateAddressActions.CREATE_ADDRESS_TYPE, createAddressSaga)
];
